import { Component, NgZone, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api/api.service';
import { FacebookService, LoginResponse } from 'ngx-facebook';
import { MessagehandlingService } from 'src/app/common/message/message-service';
import { UserService } from 'src/app/common/service/user.service';
import { environment } from 'src/environments/environment';
declare var google: any;
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm!: FormGroup
  socialloginData: FormGroup;
  facebookProfileDetail: any;
  fbtoken: any;
  userInfo: any;
  passwordType = "password"
  passwordIcon: any = "../../../../assets/images/slash-aye-light-gray.svg";

  constructor(private fb: FacebookService, private route: Router, private formBuilder: FormBuilder, private renderer: Renderer2, private zone: NgZone, private api: ApiService, private userService: UserService, private message: MessagehandlingService) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, this.emailValidator.bind(this)]],
      password: new FormControl('', Validators.required),
      rememberme: [false],
    })
    this.socialloginData = this.formBuilder.group({
      social_email: ['', [Validators.required, this.emailValidator.bind(this)]],
    })
  }

  ngOnInit() {
    this.checkRememberedLogin()
    let script = this.renderer.createElement('script');
    script.src = "https://accounts.google.com/gsi/client";
    script.defer = true;
    script.async = true;
    this.renderer.appendChild(document.body, script);

  }

  ngAfterViewInit() {
    this.signInWithGoogle()
  }


  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }

  hidesShowpassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === '../../../../assets/images/slash-aye-light-gray.svg' ? '../../../../assets/images/eye-light-gray.svg' : '../../../../assets/images/slash-aye-light-gray.svg';
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  checkRememberedLogin(): void {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    const rememberedPassword = localStorage.getItem('rememberedPassword');
    if (rememberedEmail && rememberedPassword) {
      this.loginForm.patchValue({
        email: rememberedEmail,
        password: rememberedPassword,
        rememberme: true
      });
    }
  }

  submit() {
    if (this.loginForm.valid) {
      const { email, password, rememberme } = this.loginForm.value;
      const fd = new FormData();
      fd.append('email', email);
      fd.append('password', password);
      fd.append('device_type', 'web')
      this.api.login(fd).subscribe(
        (res: any) => {
          console.log(res);
          sessionStorage.setItem('crewHub', res.data.token);
          sessionStorage.setItem('profileData', JSON.stringify(res.data));
          this.userService.fetchUser()
          if (res.data.type === 'careprovider') {
            this.message.errorMessage('Your account is not carerecipient.', 2000)
            return
            // if (res.data.is_email_verify === 0 && res.data.is_phone_verify === 0) {
            //   this.route.navigate(['provider/otp']);
            // } else if (res.data.is_email_verify === 0) {
            //   this.route.navigate(['provider/otp']);
            // } else if (res.data.is_phone_verify === 0) {
            //   this.route.navigate(['provider/profile']);
            // } else if (res.data.ndis_back == null || res.data.ndis_front == null) {
            //   this.route.navigate(['provider/worker']);
            // } else if (res.data.photo == null) {
            //   this.route.navigate(['provider/upload-profile']);
            // }
            // else if (res.data.availability == '0') {
            //   this.route.navigate(['/provider/add-availability']);
            // }
            // else {
            //   this.route.navigate(['provider/home']);
            // }
          }
          else if (res.data.type === 'recipient') {
            if (res.data.is_email_verify === 0 && res.data.is_phone_verify === 0) {
              this.route.navigate(['recipient/otp-verification']);
            } else if (res.data.is_email_verify === 0) {
              this.route.navigate(['recipient/otp-verification']);
            } else if (res.data.is_phone_verify === 0) {
              this.route.navigate(['recipient/profile']);
            }
            else if (res.data.subscription == null) {
              this.route.navigate(['recipient/payment']);
            }
            else if (res.data.subscription == null && res.data.status == 0) {
              this.route.navigate(['recipient/home']);
            }
            else {
              this.route.navigate(['recipient/home']);
            }
          }
          if (rememberme) {
            localStorage.setItem('rememberedEmail', email);
            localStorage.setItem('rememberedPassword', password);
          } else {
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('rememberedPassword');
          }
        },
        (error) => {
          console.error('Login failed', error);
        }
      );
    }
  }

  // ****************************************** Social Login ************************************************************** 

  async signInWithGoogle() {
    await google.accounts.id.initialize({
      client_id: environment.GOOGLE_CLIENT_ID,
      callback: ((res: any) => {
        console.log(res.credential)
        this.decodeJwtToken(res, 'google');
      }),
      scope: 'profile email',
    })

    await google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: 'large', type: 'icon', shape: 'circle' }
    );

  }

  // async signInWithGoogle() {
  //   // Initialize Google Sign-In
  //   await google.accounts.id.initialize({
  //     client_id: environment.GOOGLE_CLIENT_ID,
  //     callback: (res: any) => {
  //       console.log(res.credential);
  //       this.decodeJwtToken(res, 'google');
  //     },
  //     scope: 'profile email',
  //   });
  
  //   // Render Google button inside `buttonDiv`
  //   google.accounts.id.renderButton(
  //     document.getElementById('buttonDiv'),
  //     {
  //       theme: 'outline', // Keeps styling consistent
  //       size: 'large',
  //       width: '100%',
  //       type: 'standard', // Makes the button standard-sized
  //       // text: '', // Google text instead of just icon
  //       shape: 'icon',
  //     }
  //   );
  
  //   // Render functionality as soon as the page loads
  //   google.accounts.id.prompt(); 
  // }
  

  decodeJwtToken(token: any, socialType: any) {
    console.log(token, socialType)
    if (socialType == 'apple') {
      token = token
    }
    else if (socialType == 'google') {
      token = token.credential
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    this.zone.run(() => {
      if (socialType == 'google') {
        this.getLoginWithSocialProfile(JSON.parse(jsonPayload), 'google')
      }
    }
    )
  }

  async signInWithFacebook() {
    this.fb.login()
      .then((response: LoginResponse) => {
        if (response) {
          this.fbtoken = response.authResponse.accessToken
          fetch('https://graph.facebook.com/v2.12/me?fields=id,name,picture,first_name,last_name,email&access_token=' + response.authResponse.accessToken)
            .then(response => response.json())
            .then((result) => {
              if (result.hasOwnProperty("email")) {
                this.facebookProfileDetail = result
                console.log(this.facebookProfileDetail)
                this.getLoginWithSocialProfile(result, 'facebook')
              }
              else {
                this.facebookProfileDetail = result
                this.emailModal();
              }
            });
        }
      })
      .catch((error: any) => console.error(error));

  }


  async getLoginWithSocialProfile(socialProfileResponse: any, socialType: any) {
    switch (socialType) {
      case 'google':
        var social_info: any = { 'logintype': '2', 'email': socialProfileResponse.email, 'socialtype': 'google', 'socialid': socialProfileResponse.sub, 'picture': socialProfileResponse.picture, 'first_name': socialProfileResponse.given_name, 'last_name': socialProfileResponse.family_name }
        break;
      case 'facebook':
        var social_info: any = { 'logintype': '2', 'email': socialProfileResponse.email, 'socialtype': 'facebook', 'socialid': socialProfileResponse.id }
        break;
      // case 'linkedin':
      //   var social_info: any = { 'logintype': '2', 'email': socialProfileResponse.email, 'socialtype': 'linkedin', 'socialid': socialProfileResponse.sub }
      //   break;

    }
    this.userInfo = social_info
    console.log(this.userInfo);
    this.socialLogin()

  }

  signupSocialSubmit() {
    console.log(this.socialloginData.value.social_email)
    this.facebookProfileDetail['email'] = this.socialloginData.value.social_email
    $('#exampleModal').modal('hide')
    this.getLoginWithSocialProfile(this.facebookProfileDetail, 'facebook')
    this.socialloginData.reset();
  }

  emailModal() {
    $('#exampleModal').modal('show');
  }

  closeModal() {
    $('#exampleModal').modal('hide')
  }

  socialLogin() {
    // let promise = new Promise((resolve:any)=>{
    //   this.notificationService.requestPermission().then((currentToken: any) => {
    //     console.log(currentToken)
    //     if (currentToken) {
    //       this.fcmToken = currentToken
    //       resolve(this.fcmToken)
    //     }

    //     else {
    //       this.fcmToken = ''
    //       resolve(this.fcmToken)
    //     }
    //   }).catch((err: any) => {
    //     console.log('An error occurred while retrieving token. ', err);
    //      this.fcmToken = ''
    //     resolve(this.fcmToken)

    //   });
    // })

    // promise.then((res:any)=>{
    let data = {
      device_type: 'web',
      email: this.userInfo.email,
      socialtype: this.userInfo.socialtype,
      social_id: this.userInfo.socialid,
      type: 'recipient',
      first_name: this.userInfo.first_name,
      last_name: this.userInfo.last_name,
      photo: this.userInfo.picture
      // devToken: this.fcmToken
    }
    this.api.socialSignup(data).subscribe((res: any) => {
      if (res.status == 1) {
        this.message.successMessage(res.msg, 2000)
        sessionStorage.setItem('crewHub', res.data.token);
        sessionStorage.setItem('profileData', JSON.stringify(res.data));
        if (res.data.type === 'recipient') {
          if (res.data.is_email_verify === 0 && res.data.is_phone_verify === 0) {
            this.route.navigate(['recipient/otp-verification']);
          } else if (res.data.is_email_verify === 0) {
            this.route.navigate(['recipient/otp-verification']);
          } else if (res.data.is_phone_verify === 0) {
            this.route.navigate(['recipient/profile']);
          }
          else if (res.data.subscription == null) {
            this.route.navigate(['recipient/payment']);
          }
          // else if (res.data.subscription == null && res.data.status == 0) {
          //   this.route.navigate(['recipient/home']);
          // }
          else {
            this.route.navigate(['recipient/home']);
          }
        }
      } else {
        this.message.errorMessage(res.message, 2000)
      }
    })

  }

}
