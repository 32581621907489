<section class="body-font h-full items-center">
  <div class="flex flex-wrap gap-5">
    <div class="2xl:w-1/2 xl:w-1/2 lg:full md:w-full sm:w-full grid items-center h-screen w-full md:py-0 py-10">
      <form class="mx-auto 2xl:w-6/12 xl:w-8/12 lg:w-12/12 md:w-8/12 sm:w-full md:px-0 sm:px-10 w-full px-6"
        [formGroup]="loginForm">
        <h2 class="pb-2 lg:text-4xl sm:text-3xl text-3xl  font700 md:text-center lg:text-start text-center">Hi, Welcome
          Back!</h2>
        <p class="text-base text-gray-500 pb-7 md:text-center lg:text-start text-center">Enter your login information to
          continue</p>
        <div class="mb-5 relative">
          <label for="email" class="block mb-2 text-sm font400 text-dark">Email address</label>
          <input type="text" formControlName="email" (keydown)="checkEvent($event)"
            class="bg-slate-50 border border-slate-300 pl-12 h-14 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="john@example.com" />
          <div *ngIf="!loginForm.controls?.['email']?.valid && loginForm.controls?.['email']?.dirty">
            <div *ngIf="loginForm.controls?.['email']?.hasError('invalidEmail') && loginForm.controls?.['email']?.value"
              class="text-red-500">Invalid email !</div>
            <div class="text-red-500" *ngIf="loginForm.controls?.['email']?.hasError('required')">
              This field is Mandatory !</div>
          </div>
          <span class="absolute top-11 left-4">
            <img src="../../../../assets/images/email-light-gray.svg" alt="">
          </span>
        </div>
        <div class="mb-5 relative">
          <label for="password" class="block mb-2 text-sm font400  text-dark">Password</label>
          <input [type]="passwordType" id="password" formControlName="password" (keydown)="checkEvent($event)"
            class="bg-gray-50 border border-slate-300	h-14 pl-12 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter password" />
          <div *ngIf="!loginForm.controls?.['password']?.valid && loginForm.controls?.['password']?.dirty"
            class="error_main_div">
            <div *ngIf="loginForm.controls?.['password']?.hasError('required')" class="text-red-500">This field is
              Mandatory ! </div>
          </div>
          <span class="absolute top-11 left-4">
            <img src="../../../../assets/images/lock-light-gray.svg" alt="">
          </span>
          <span class="absolute top-11 right-3" (click)="hidesShowpassword()"><img src="{{passwordIcon}}"
              alt="" /></span>
        </div>
        <div class="flex align-items-center justify-between">
          <div class="flex items-start mb-5">
            <div class="flex items-center h-5">
              <input id="remember" type="checkbox" value="" formControlName="rememberme"
                class="w-4 h-4 border border-slate-300 rounded checked:bg-blue focus:bg-blue" />
            </div>
            <label for="remember" class="ms-2 text-base text-[#081735]">Remember me</label>
          </div>
          <div class="font500 text-blue text-base hover:text-hover_blue cursor-pointer"
            [routerLink]="['/forgot-password']">Forgot password?</div>
        </div>
        <button type="submit" (click)="submit()" [disabled]="!(loginForm.value.email && loginForm.value.password)"
          class="text-white h-14 font500 text-base shadow-lg bg-blue hover:bg-hover_blue focus:ring-4 
          focus:outline-none focus:ring-blue-300 font-medium rounded-lg w-full px-5 py-2.5 text-center">
          Log In
        </button>
        <div class="relative sign-in-block text-center pt-6 pb-4">
          <p class="font500 text-gray relative">Or sign in with social media</p>
        </div>
        <div class="sm:flex lg:flex flex gap-3 mt-3 mb-3 items-center justify-center">
          <!-- <button class="bg-transparent border border-[#D8DAE5] rounded-xl text-[#081735] flex items-center -->
           <!-- font700 w-20 lg:w-44 h-14 gap-2 justify-center sm:mb-0 mb-3"> -->
            <div class="g_button">
              <span class="googlbtn">
                <div id="buttonDiv" class="cstm_btn"></div>
              </span>
            </div>
            <!-- <span class="hidden lg:block">Google</span> -->
          <!-- </button> -->

          <!-- <button
            class="bg-transparent border border-[#D8DAE5] rounded-xl text-[#081735] flex items-center font700 w-20 lg:w-44 h-14 gap-2 justify-center sm:mb-0 mb-3 relative"
            id="googleSignInButtonWrapper">
            <div id="buttonDiv" class="absolute inset-0 flex items-center justify-center" style="z-index: 1;"></div>
            <span class="hidden lg:block text-[#081735] font700" style="z-index: 0;">Google</span>
          </button> -->


          <!-- <button class="bg-transparent border border-[#D8DAE5] rounded-xl text-[#081735] flex items-center
           font700 w-20 lg:w-44 h-14 gap-2 justify-center sm:mb-0 mb-3"> -->
            <img class="border-[1px] border-[#E5E7EB] border-solid rounded-full w-10 h-10 p-2" src="../../../assets/images/apple.png" />
            <!-- <span class="hidden lg:block">Apple</span> -->
          <!-- </button> -->
          <!-- for facebook (click)="signInWithFacebook()" -->
          <!-- <button class="bg-transparent border border-[#D8DAE5] rounded-xl text-[#081735] flex items-center font700 w-20
             lg:w-44 h-14 gap-2 justify-center"> -->
            <img src="../../../assets/images/facebook.png" class="border-[1px] border-[#E5E7EB] border-solid rounded-full w-10 h-10 p-2"/>
            <!-- <span class="hidden lg:block">Facebook</span> -->
          <!-- </button> -->
        </div>
        <p class="pt-3 mb-5 text-gray-500">Not registered yet? <a [routerLink]="['/landing']"
            class="text-blue font700 underline">Sign Up</a></p>
      </form>
    </div>
    <div class="lg:w-1/2 md:w-1/2 w-full bg-gray-50 h-screen bg-no-repeat bg-cover bg-bottom fixed top-0 bottom-0 
         right-0 invisible lg:visible" style="background-image: url('../../../assets/images/login-side-img.png')">
      <div class="d-flex align-items-center w-full h-full justify-items-center pt-20 logo-block-img">
        <img src="../../../assets/images/crew-hub-logo.png" alt="" class="mx-auto" />
      </div>
    </div>
  </div>
</section>